import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useMatch, useNavigate } from 'react-router'
import { Route, Routes } from 'react-router-dom'
import { LogAccessEvent } from '../../api/events'
import mf from '../../assets/img/mf.jpg'
import { useDebounce } from '../../hooks/Debounce'
import { useMountEffect } from '../../hooks/MountEffect'
import { useTypedSelector } from '../../redux/reducers'
import {
  getAccountDetails,
  getConnectionStatus,
  getFeatureUsage,
  getHostStatus,
  getPmitcSessionLogoutLink,
  getPmitcSessionName,
  getPmitcSessionWindow,
  getSystemHealth,
  getSystemInfo,
  isDemo,
} from '../../redux/selectors'
import { clearHost } from '../../redux/slideout'
import AuthRole from '../../role'
import { LinkData } from '../Access/Data'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { manageHiveCustomer } from '../Hive/HiveManage/HiveManage'
import { ConnectionStatus, EditCustomerFunction, HostData, HostDataUtil, HostStatus } from '../Host/Data'
import { ExclaimIcon, RemoteManageIcon } from '../Icons/Icons'
import { Note } from '../Note/Note'
import PmitcSessionModal from '../PmitcSessionModal/PmitcSessionModal'
import RouteTab from '../Tabs/RouteTab'
import Toaster from '../Toaster'
import { AccountTab } from './AccountTab'
import { HostSlideoutTab } from './Data'
import { DevicesTab } from './DevicesTab'
import { EventLogTab } from './EventLogTab'
import { FeatureUsageTab } from './FeatureUsageTab'
import { HeaderSpecs } from './HeaderSpecs'
import { HiveAccountTab } from './HiveAccountTab'
import { HiveFeatureUsageTab } from './HiveFeatureUsageTab'
import { HiveServiceHealthTab } from './HiveServiceHealthTab'
import { RemoteMonitoringDisabledTab } from './RemoteMonitoringDisabledTab'
import { SystemHealthTab } from './SystemHealthTab'

interface Props {
  host: HostData
  teamID: string
  userName: string
  editCustomer: EditCustomerFunction
  startTab?: HostSlideoutTab
}

export const HostSlideout: FunctionComponent<Props> = ({ host, teamID, userName, editCustomer, startTab }) => {
  const account = useTypedSelector(getAccountDetails(host.id))
  const systemInfo = useTypedSelector(getSystemInfo(host.id))
  const systemHealth = useTypedSelector(getSystemHealth(host.id))
  const hostStatus = useTypedSelector(getHostStatus(host.id))
  const featureUsage = useTypedSelector(getFeatureUsage(host.id))
  const connectionStatus = useTypedSelector(getConnectionStatus(host.id))
  const demoMode = useTypedSelector(isDemo)
  const canRemoteManage = AuthRole.hasPermission('remoteManage')
  const canManagePmitc = canRemoteManage && connectionStatus !== ConnectionStatus.Disabled
  const remoteEnabled = canRemoteManage && connectionStatus === ConnectionStatus.Live
  const manageDevicesEnabled = canRemoteManage && connectionStatus !== ConnectionStatus.Disconnected
  const isPmitc = HostDataUtil.isPmitcProduct(host.product)
  const productName = HostDataUtil.getProductText(systemInfo?.product ?? host.product)
  const remoteMonitoringDisabled = hostStatus === HostStatus.MonitoringDisabled

  const [remoteSession, setRemoteSession] = useState(false)
  const [remoteDevice, setRemoteDevice] = useState(false)
  const [devices, setDevices] = useState<LinkData[]>([])
  const [deviceSearch, setDeviceSearch] = useState('')
  const [sessionTime, setSessionTime] = useState(0)
  const [showEndSessionConfirmModal, setShowEndSessionConfirmModal] = useState(false)
  const [closeOnEndSession, setCloseOnEndSession] = useState(false)
  const remoteView = remoteSession || remoteDevice
  const debouncedDeviceSearchFilter = useDebounce(deviceSearch, 500)
  const filteredDevices = devices.filter(d => {
    let searchTerm = debouncedDeviceSearchFilter.trim().toLowerCase()
    if (searchTerm) {
      return d.name.toLowerCase().includes(searchTerm) || (d.subName?.toLowerCase().includes(searchTerm) ?? false)
    }
    return true
  })

  const ref = useRef<HTMLHtmlElement>(null)
  const overlay = useRef<HTMLDivElement>(null)

  let match = useMatch('/my-customers/:subpath/*')
  let subPath = match?.params.subpath ?? 'list'
  const navigateTo = useNavigate()
  useMountEffect(() => {
    navigateTo('/my-customers/' + subPath + '/' + (startTab ?? HostSlideoutTab.Account))
  })

  const dispatch = useDispatch()
  const existingSessionLogoutLink = useTypedSelector(getPmitcSessionLogoutLink)
  const existingSessionName = useTypedSelector(getPmitcSessionName)
  const existingSessionWindow = useTypedSelector(getPmitcSessionWindow)
  const [showEndPmitcSessionConfirmModal, setShowEndPmitcSessionConfirmModal] = useState(false)

  const escapeListener = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.keyCode === 27) exitSlideout()
  }

  useMountEffect(() => {
    overlay.current?.addEventListener('keyup', escapeListener)
    return () => {
      overlay.current?.removeEventListener('keyup', escapeListener)
    }
  })

  const close = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    exitSlideout()
  }

  const exitSlideout = () => {
    if (remoteSession) {
      setCloseOnEndSession(true)
      setShowEndSessionConfirmModal(true)
    } else {
      doClose()
    }
  }

  const doClose = () => {
    if (ref.current) ref.current.classList.remove('visible')
    setTimeout(() => {
      dispatch(clearHost())
      navigateTo('/my-customers/' + subPath)
    }, 300)
  }

  const openRemoteSession = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    LogAccessEvent(teamID, host.id, userName, productName)
    setRemoteSession(true)
  }

  const handleEndSession = () => {
    setCloseOnEndSession(false)
    setShowEndSessionConfirmModal(true)
  }

  const endRemoteSession = () => {
    setShowEndSessionConfirmModal(false)
    setRemoteSession(false)
    if (closeOnEndSession) {
      doClose()
    }
  }

  const manageDevices = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    axios
      .get<any>('/api/teams/' + teamID + '/hosts/' + host.id + '/devices')
      .then(resp => {
        const mfds: LinkData[] = []
        resp.data.forEach((dev: any) => {
          mfds.push({
            name: dev.deviceName,
            subName: dev.deviceType,
            url: dev.remoteURL,
          })
        })
        setDevices(mfds)
        setRemoteDevice(true)
      })
      .catch(_ => Toaster.notifyFailure('Unable to load device list.'))
  }

  const handleDeviceBack = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setRemoteDevice(false)
  }

  useEffect(() => {
    if (remoteSession) {
      const interval = setInterval(() => setSessionTime(seconds => seconds + 1), 1000)
      return () => clearInterval(interval)
    } else {
      setSessionTime(0)
    }
  }, [remoteSession])

  useMountEffect(() => {
    if (ref.current) {
      ref.current.classList.add('visible')
    }
    if (overlay.current) {
      const overlayRef = overlay.current
      setTimeout(() => (overlayRef.style.opacity = '1'), 300)
      overlay.current.style.zIndex = '9'
    }
  })

  const remoteNavBar = (
    <div className='rm-pc'>
      <i className='icon twenty'>
        <RemoteManageIcon />
      </i>
      <div className='row flex flex-nowrap'>
        <div className='col customer-product'>
          <h4>{productName}</h4>
          <small>v{systemInfo?.version ?? account?.paperCutVersion ?? '--'}</small>
        </div>
        <div className='col'>
          {Math.floor(sessionTime / 60)}m {sessionTime % 60}s<small>Session time</small>
        </div>
        <div className='rgt col'>
          <a href={demoMode ? mf : host.remoteManageLink} className='fullscreen' target='_blank' rel='noopener noreferrer'>
            Full screen
          </a>
          <button className='btn secondary sml end' onClick={handleEndSession}>
            End session
          </button>
        </div>
      </div>
    </div>
  )

  const deviceNavBar = (
    <div className='rm-mfd'>
      <div className='row flex flex-nowrap flex-ver'>
        <div className='col third'>
          <h4>Manage devices {devices.length > 0 && <>({devices.length})</>}</h4>
        </div>
        <div className='col third'>
          <input placeholder='Search by name or type' type='text' value={deviceSearch} onChange={e => setDeviceSearch(e.currentTarget.value)} />
        </div>
        <div className='col rgt'>
          <a href='#' className='btn secondary sml back' onClick={handleDeviceBack}>
            Back
          </a>
        </div>
      </div>
    </div>
  )

  const accountTab = () => {
    if (isPmitc) {
      return <HiveAccountTab teamID={teamID} editCustomer={editCustomer} host={host} />
    }
    return (
      <AccountTab
        teamID={teamID}
        editCustomer={editCustomer}
        host={host}
        upgradeRequired={HostDataUtil.newVersionRequired(HostDataUtil.getVersion(systemInfo, account))}
        systemInfo={systemInfo}
        account={account}
      />
    )
  }

  const healthTab = () => {
    if (isPmitc) {
      return <HiveServiceHealthTab />
    }
    return !remoteMonitoringDisabled && systemHealth ? <SystemHealthTab systemHealth={systemHealth} hostStatus={hostStatus} /> : <RemoteMonitoringDisabledTab name={host.name} />
  }

  const featureUsageTab = () => {
    if (isPmitc) {
      return <HiveFeatureUsageTab customerName={host?.name ?? ''} featureUsage={featureUsage} hostStatus={hostStatus} />
    }
    return remoteMonitoringDisabled ? (
      <RemoteMonitoringDisabledTab name={host.name} />
    ) : (
      <FeatureUsageTab customerName={host?.name ?? ''} featureUsage={featureUsage} hostStatus={hostStatus} />
    )
  }

  const eventLogTab = () => <EventLogTab teamID={teamID} hostID={host.id} />
  const notesTab = () => (
    <div id='tab-notes'>
      <header className='flex flex-ver'>
        <h2>Notes</h2>
      </header>
      <Note teamID={teamID} hostID={host.id} top={true} />
    </div>
  )

  const handleManage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (existingSessionWindow && !existingSessionWindow.closed) {
      setShowEndPmitcSessionConfirmModal(true)
    } else {
      manageHiveCustomer(dispatch, teamID, host, userName, demoMode)
    }
  }

  return (
    <>
      <aside ref={ref} className='org'>
        <article className='customer alt'>
          <div className='flex fp'>
            <header>
              <HeaderSpecs host={host} onClose={close} />
              {hostStatus === HostStatus.StaleData && (
                <div className='alert error flex-ver'>
                  <i className='icon'>
                    <ExclaimIcon />
                  </i>
                  <div className='message'>
                    PaperCut Multiverse is having trouble connecting to this customer environment. Please check that the PaperCut Multiverse Connector is running as expected.
                  </div>
                </div>
              )}
              <div className='tabs-wrap'>
                <div className={`tabs five new ${remoteView ? 'remoteopen' : ''}`}>
                  <ul>
                    <RouteTab path={`/my-customers/${subPath}/${HostSlideoutTab.Account}`}>Account</RouteTab>
                    <RouteTab path={`/my-customers/${subPath}/${HostSlideoutTab.SystemHealth}`}>{isPmitc ? 'Edge Mesh health' : 'System health'}</RouteTab>
                    <RouteTab path={`/my-customers/${subPath}/${HostSlideoutTab.FeatureUsage}`}>Feature usage</RouteTab>
                    <RouteTab path={`/my-customers/${subPath}/${HostSlideoutTab.EventLog}`}>Event log</RouteTab>
                    <RouteTab path={`/my-customers/${subPath}/${HostSlideoutTab.Notes}`}>Notes</RouteTab>
                  </ul>
                  <div className='rgt btn-group'>
                    {isPmitc && (
                      <a className={`btn rm sml outline inv ${canManagePmitc ? '' : 'disabled'}`} href='#' onClick={handleManage}>
                        Manage
                      </a>
                    )}

                    {!isPmitc && (
                      <>
                        <a className={`btn rm sml outline inv ${remoteEnabled ? '' : 'disabled'}`} href='#' onClick={openRemoteSession}>
                          Manage PaperCut
                        </a>
                        <a className={`btn rm sml outline inv ${manageDevicesEnabled ? '' : 'disabled'}`} href='#' onClick={manageDevices}>
                          Manage devices
                        </a>
                      </>
                    )}
                  </div>
                  <div className='navbar'>
                    {remoteSession && remoteNavBar}
                    {remoteDevice && deviceNavBar}
                  </div>
                </div>
              </div>
            </header>
          </div>
        </article>

        {!remoteView && (
          <div className='scroll'>
            <Routes>
              <Route path={`/my-customers/${subPath}/${HostSlideoutTab.Account}`} element={accountTab()} />
              <Route path={`/my-customers/${subPath}/${HostSlideoutTab.SystemHealth}`} element={healthTab()} />
              <Route path={`/my-customers/${subPath}/${HostSlideoutTab.FeatureUsage}`} element={featureUsageTab()} />
              <Route path={`/my-customers/${subPath}/${HostSlideoutTab.EventLog}`} element={eventLogTab()} />
              <Route path={`/my-customers/${subPath}/${HostSlideoutTab.Notes}`} element={notesTab()} />
            </Routes>
          </div>
        )}
        {remoteSession && (
          <div className='scroll'>
            <div id='tab-remote' className='tab'>
              <div className='window'>{demoMode ? <img src={mf} alt='mf' /> : <iframe title={host.name} src={host.remoteManageLink} />}</div>
            </div>
          </div>
        )}
        {remoteDevice && <DevicesTab teamID={teamID} hostID={host.id} devices={filteredDevices} allDevicesCount={devices.length} />}
        {showEndSessionConfirmModal && (
          <ConfirmationModal
            title='Are you sure you want to end your session?'
            message={`Session time: ${Math.floor(sessionTime / 60)}m ${sessionTime % 60}s`}
            onClose={() => setShowEndSessionConfirmModal(false)}
            onYes={endRemoteSession}
            cannotUndoWarning={false}
          />
        )}
        {showEndPmitcSessionConfirmModal && (
          <PmitcSessionModal
            customer={existingSessionName}
            onClose={() => setShowEndPmitcSessionConfirmModal(false)}
            onYes={() => {
              if (existingSessionWindow && existingSessionLogoutLink) {
                existingSessionWindow.location.href = existingSessionLogoutLink
              }

              manageHiveCustomer(dispatch, teamID, host, userName, demoMode)
            }}
          />
        )}
      </aside>
      <div ref={overlay} className='overlay' onClick={exitSlideout} style={{ opacity: 0, zIndex: -1 }} />
    </>
  )
}
