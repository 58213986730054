import React, { FunctionComponent } from 'react'
import { ErrorIcon, ExclaimIcon, MeshIcon, PeopleIcon, PrinterIcon, TickIcon } from '../../Icons/Icons'

interface Props {
  onClose: () => void
}

const HiveServiceHealth: FunctionComponent<Props> = props => {
  const onClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    props.onClose()
  }

  return (
    <div id='tab-health' className='tab'>
      <header>
        Cloud Print Management Service health at a glance
        <a href='#' className='action' onClick={onClose} aria-label='Close Edge Mesh health tab' />
      </header>

      <div className='content upgrade-req coming-soon'>
        <span className='sample'>Need this?</span>
        <div className='row flex'>
          <div className='col third'>
            <div className='card'>
              <header>
                <a href='#' className='expand hide'>
                  Expand
                </a>
                <i className='icon'>
                  <MeshIcon />
                </i>
                <h6>Edge Mesh</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Overall health</h5>
                  <div className='status'>
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                </div>
                <div className='set alt'>
                  <h5>Super nodes</h5>
                  <div className='status'>
                    2
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                </div>
                <div className='set'>
                  <h5>Standard nodes</h5>
                  <div className='status'>
                    5
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                </div>
                <div className='set alt'>
                  <h5>Passive nodes</h5>
                  <div className='status'>
                    10
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                </div>
              </div>
              <div className='expanded'>
                <div className='set'>
                  <h5>Database</h5>
                  <div className='status'>
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                  <div className='stats'>
                    <ul>
                      <li>
                        <span className='label'>Max connections</span> <span className='num'>420</span>
                      </li>
                      <li>
                        <span className='label'>Connections used</span> <span className='num'>21</span>
                      </li>
                      <li>
                        <span className='label'>Response time</span> <span className='num'>3</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='set alt'>
                  <h5>Disk space</h5>
                  <div className='status'>
                    <i className='icon sixteen error'>
                      <ErrorIcon />
                    </i>
                  </div>
                  <div className='stats'>
                    <ul>
                      <li>
                        <span className='label'>Total</span> <span className='num'>1,024GB</span>
                      </li>
                      <li>
                        <span className='label'>Free</span> <span className='num error'>2GB</span>
                      </li>
                      <li>
                        <span className='label'>Used</span> <span className='num'>1,022GB</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='set'>
                  <h5>CPU</h5>
                  <div className='status'>
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                  <div className='stats'>
                    <ul>
                      <li>
                        <span className='label'>Cores/processors</span> <span className='num'>2</span>
                      </li>
                      <li>
                        <span className='label'>System CPU</span> <span className='num'>58%</span>
                      </li>
                      <li>
                        <span className='label'>Application Server CPU</span> <span className='num'>17%</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='set alt'>
                  <h5>Application memory</h5>
                  <div className='status'>
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                  <div className='stats'>
                    <ul>
                      <li>
                        <span className='label'>Max</span> <span className='num'>1,820MB</span>
                      </li>
                      <li>
                        <span className='label'>Allocated</span> <span className='num'>928MB</span>
                      </li>
                      <li>
                        <span className='label'>Used</span> <span className='num'>411MB</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col third'>
            <div className='card'>
              <header>
                <a href='#' className='expand hide'>
                  Expand
                </a>
                <i className='icon'>
                  <PeopleIcon />
                </i>
                <h6>Users</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Total users</h5>
                  <div className='status'>
                    27
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                </div>
                <div className='set alt'>
                  <h5>
                    <span>Active users</span>
                  </h5>
                  <div className='status'>
                    11/27
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                </div>
                <div className='set'>
                  <h5>Inactive users</h5>
                  <div className='status'>
                    3/27
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                </div>
                <div className='set alt'>
                  <h5>Pending invites</h5>
                  <div className='status'>
                    13/27
                    <i className='icon sixteen warning'>
                      <ExclaimIcon />
                      <span className='tip'>PaperCut Multiverse is having trouble connecting to some devices</span>
                    </i>
                  </div>
                </div>
              </div>
              <div className='expanded'>
                <div className='col half flex'>
                  <div className='set'>
                    <h5>Print Providers</h5>
                    <div className='status'>
                      <i className='icon sixteen'>
                        <TickIcon />
                      </i>
                    </div>
                    <div className='stats'>
                      <ul>
                        <li>
                          Total <span className='num'>1</span>
                        </li>
                        <li>
                          Online <span className='num'>1</span>
                        </li>
                        <li>
                          Offline <span className='num'>0</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='set alt'>
                    <h5>
                      <span>Site Servers</span>
                    </h5>
                    <div className='status'>
                      <i className='icon sixteen'>
                        <TickIcon />
                      </i>
                    </div>
                    <div className='stats'>
                      <ul>
                        <li>
                          Total <span className='num'>3</span>
                        </li>
                        <li>
                          Online <span className='num'>2</span>
                        </li>
                        <li>
                          Offline <span className='num'>1</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='set'>
                    <h5>BYOD / Mobility Print</h5>
                    <div className='status'>
                      <i className='icon sixteen'>
                        <TickIcon />
                      </i>
                    </div>
                    <div className='stats'>
                      <ul>
                        <li>
                          Total <span className='num'>1</span>
                        </li>
                        <li>
                          Online <span className='num'>0</span>
                        </li>
                        <li>
                          Offline <span className='num'>1.0.2345</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col half flex'>
                  <div className='set alt'>
                    <h5>Offline MFDs/printers</h5>
                    <div className='status'>
                      <i className='icon sixteen warning'>
                        <ExclaimIcon />
                      </i>
                    </div>
                    <div className='stats'>
                      <ul>
                        <li>
                          PRTSVR\MFD014 (500 Brk L36){' '}
                          <span className='num'>
                            <small className='error'>Offline</small>
                          </span>
                        </li>
                        <li>
                          PRTSVR\MFD201 (714 Brk L10){' '}
                          <span className='num'>
                            <small className='error'>Offline</small>
                          </span>
                        </li>
                        <li>
                          PRTSVR\MFD007 (500 Brk L12){' '}
                          <span className='num'>
                            <small className='error'>Unknown error</small>
                          </span>
                        </li>
                        <li>
                          PRTSVR\MFD002 (500 Brk L18){' '}
                          <span className='num'>
                            <small>No recent jobs</small>
                          </span>
                        </li>
                        <li>
                          PRTSVR\MFD023 (714 Brk L30){' '}
                          <span className='num'>
                            <small>Paper out</small>
                          </span>
                        </li>
                        <li>
                          PRTSVR\PRN087 (714 Brk L15){' '}
                          <span className='num'>
                            <small>Offline</small>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col third'>
            <div className='card'>
              <header>
                <a href='#' className='expand hide'>
                  Expand
                </a>
                <i className='icon twelve'>
                  <PrinterIcon />
                </i>

                <h6>Printers</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Total printers</h5>
                  <div className='status'>
                    8
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                </div>
                <div className='set alt'>
                  <h5>Printers in error</h5>
                  <div className='status'>
                    1/8
                    <i className='icon sixteen'>
                      <TickIcon />
                    </i>
                  </div>
                </div>
                <div className='set'>
                  <h5>Toner required</h5>
                  <div className='status'>
                    4/8
                    <i className='icon sixteen warning'>
                      <ExclaimIcon />
                      <span className='tip'>PaperCut Multiverse is having trouble connecting to some devices</span>
                    </i>
                  </div>
                </div>
              </div>
              <div className='expanded'>
                <div className='set'>
                  <h5>Users</h5>
                  <div className='status'>3234</div>
                  <div className='stats'>
                    <ul>
                      <li>
                        Registered users <span className='num'>3234</span>
                      </li>
                      <li>
                        Active users (6 months) <span className='num'>2254</span>
                      </li>
                      <li>
                        Active users (Last month) <span className='num'>1254</span>
                      </li>
                      <li>
                        Active users (Last week) <span className='num'>254</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='set alt'>
                  <h5>Total devices</h5>
                  <div className='status'>287</div>
                  <div className='stats'>
                    <ul>
                      <li>
                        PaperCut embeddeds <span className='num'>31</span>
                      </li>
                      <li>
                        Other printers <span className='num'>131</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='set'>
                  <h5>Jobs printed</h5>
                  <div className='status'>58,486</div>
                  <div className='stats'>
                    <ul>
                      <li>
                        Via PaperCut Embedded <span className='num'>38285</span>
                      </li>
                      <li>
                        Via PaperCut Web Print <span className='num'>382</span>
                      </li>
                      <li>
                        Via external interface <span className='num'>12</span>
                      </li>
                      <li>
                        Via Mobility Print <span className='num'>3290</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <a href='#' className='btn'>
          Knowledge Base
        </a>
        <a href='#' className='btn'>
          Download logs
        </a>
      </footer>
    </div>
  )
}

export default HiveServiceHealth
