import React, { FunctionComponent, useCallback, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import pAll from 'p-all'
import { shallowEqual, useDispatch } from 'react-redux'
import { useMatch } from 'react-router'
import { Navigate, Route, Routes } from 'react-router-dom'
import { BulkApiResponse } from '../../api/bulk'
import { DataCenter, getDataCenter } from '../../env'
import { Features } from '../../features'
import { useMountEffect } from '../../hooks/MountEffect'
import { updateAccountDetails, updateConnectionStatus, updateDevices, updateFeatureUsage, updateRemoteMonitoring, updateSystemHealth, updateSystemInfo } from '../../redux/hosts'
import { setNotificationPauses } from '../../redux/notificationPause'
import { useTypedSelector } from '../../redux/reducers'
import { isDemo, isFeatureOn } from '../../redux/selectors'
import AuthRole from '../../role'
import { AccessConfig } from '../Access/Data'
import { AccountDataUtil } from '../Account/Data'
import { AddCustomerFunction, ConnectionStatus, EditCustomerFunction, HostData, HostDataUtil } from '../Host/Data'
import { HostPropertiesFilter } from '../HostPropertiesFilter/HostPropertiesFilter'
import { LicenseView } from '../LicenseView/LicenseView'
import { NotificationPause } from '../PauseNotificationsModal/Data'
import { SystemHealthDataUtil } from '../SystemHealth/Data'
import RouteTabLink from '../Tabs/RouteTab'
import { Tab } from '../Tabs/Tabs'
import Toaster from '../Toaster'
import MyHosts from './MyHosts'

interface Props {
  addCustomer: AddCustomerFunction
  editCustomer: EditCustomerFunction
  deleteCustomer: (id: string) => void
  openAccessFrame: (config: AccessConfig) => void
  hosts: HostData[]
  teamID: string
  migrating: boolean
}

interface RouteTab extends Tab {
  path: string
}

const timeout = getDataCenter() === DataCenter.Test ? 10000 : 5000

export const MyHostsContainer: FunctionComponent<Props> = props => {
  const demoMode = useTypedSelector(isDemo)
  const licenseViewEnabled = useTypedSelector(isFeatureOn(Features.LicenseView)) || demoMode
  const accountDetails = useTypedSelector(state => state.hosts.filter(h => h.accountDetails), shallowEqual)

  const dispatch = useDispatch()
  const randomIntervalSeconds = (min: number, max: number) => {
    return 1000 * randomInt(min, max)
  }

  const randomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const getConnectionInfo = useCallback(
    (hosts: HostData[], signal: AbortSignal): (() => Promise<void>)[] => {
      const ids = hosts.map(h => h.id).join(',')
      let getFuncs: (() => Promise<void>)[]
      if (demoMode) {
        getFuncs = [
          () =>
            Promise.resolve()
              .then(_ => hosts.forEach(h => dispatch(updateConnectionStatus(h.id, ConnectionStatus.Live))))
              .catch(_ => {}),
        ]
      } else {
        getFuncs = [
          () =>
            axios
              .get<BulkApiResponse[]>(`/api/teams/${props.teamID}/customers/connected/read_many`, {
                timeout,
                signal,
                params: { ids },
              })
              .then(resp => {
                resp.data.forEach(r => {
                  let status = ConnectionStatus.Disconnected
                  if (r.status === 423) {
                    status = ConnectionStatus.Disabled
                  } else if (r.status === 200 && r.data.connected) {
                    status = ConnectionStatus.Live
                  }
                  dispatch(updateConnectionStatus(r.id, status))
                })
              })
              .catch(_ => {}),
        ]
      }
      const mfHosts = hosts
        .filter(h => !HostDataUtil.isPmitcProduct(h.product))
        .map(h => h.id)
        .join(',')
      if (mfHosts.length > 0) {
        getFuncs.push(() =>
          axios
            .get<BulkApiResponse[]>(`/api/teams/${props.teamID}/customers/devices/count/read_many`, {
              timeout,
              signal,
              params: { ids: mfHosts },
            })
            .then(resp => {
              resp.data.forEach(r => {
                const count = r.data.count
                dispatch(updateDevices(r.id, +count > 0))
              })
            })
            .catch(_ => {
              // need to catch timeouts
            })
        )
      }
      return getFuncs
    },
    [demoMode, dispatch, props.teamID]
  )

  const loadData = useCallback(
    async (signal: AbortSignal): Promise<NodeJS.Timeout | undefined> => {
      let buffer: (() => Promise<void>)[] = []
      let batchSize = 5
      // always sort by id so order stays consistent
      let orderedHosts = props.hosts.map(h => h).sort((a, b) => a.id.localeCompare(b.id))
      // chunk into batch size
      sliceIntoChunks(orderedHosts, batchSize).forEach(batch => {
        let b = batch.filter(h => !HostDataUtil.isPmitcProduct(h.product))
        let accountBatch = batch.filter(h => {
          const a = accountDetails.find(aDetails => aDetails.id === h.id)
          return !a || isExpired(demoMode, a?.accountDetails?.lastUpdate)
        })
        if (b.length > 0)
          buffer.push(() =>
            getSystemHealthInfo(props.teamID, b, signal).then(response => {
              for (const r of response) {
                if (r.status === 423) {
                  dispatch(updateRemoteMonitoring(r.id, false))
                  if (demoMode) {
                    dispatch(updateConnectionStatus(r.id, ConnectionStatus.Disabled))
                  }
                  continue
                } else if (r.status !== 200) {
                  continue
                }
                const host = r.data.customerID
                const systemHealth = SystemHealthDataUtil.getSystemHealthFromResponse(r.data)
                dispatch(updateSystemHealth(host, systemHealth))
                const featureUsage = SystemHealthDataUtil.getFeatureUsageFromResponse(r.data)
                if (featureUsage) {
                  dispatch(updateFeatureUsage(host, featureUsage))
                }

                dispatch(updateSystemInfo(host, SystemHealthDataUtil.getSystemInfoFromResponse(host, r.data)))
                dispatch(updateRemoteMonitoring(host, true))
              }
            })
          )
        if (accountBatch.length > 0)
          buffer.push(() =>
            getAccountDetails(props.teamID, accountBatch, signal).then(resp => {
              resp.data.forEach(r => {
                if (r.status === 200) {
                  dispatch(updateAccountDetails(r.id, AccountDataUtil.getAccountDetailsFromResponse(r.data)))
                }
              })
            })
          )

        if (AuthRole.hasPermission('remoteManage')) {
          getConnectionInfo(batch, signal).forEach(func => buffer.push(func))
        }
      })
      await pAll(buffer, { concurrency: 4 })
      return setTimeout(() => loadData(signal), randomIntervalSeconds(90, 150))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [demoMode, accountDetails, dispatch, randomIntervalSeconds]
  )

  useEffect(() => {
    const controller = new AbortController()
    let loadDataTimer: NodeJS.Timeout
    loadData(controller.signal).then(result => {
      if (result) {
        loadDataTimer = result
      }
    })

    return () => {
      clearTimeout(loadDataTimer)
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hosts.length])

  useMountEffect(() => {
    loadNotificationPauses()
    const timer = setInterval(loadNotificationPauses, 5 * 60 * 1000)
    return () => {
      clearInterval(timer)
    }
  })

  const loadNotificationPauses = () => {
    axios
      .get<NotificationPause[]>(`/api/teams/${props.teamID}/notifications/pauses`)
      .then(result => {
        let data = result.data ?? []
        dispatch(setNotificationPauses(data))
      })
      .catch(_ => Toaster.notifyFailure('Unable to load notification pauses.'))
  }

  let customerTab: RouteTab = {
    name: 'Customers',
    component: (
      <MyHosts
        addCustomer={props.addCustomer}
        editCustomer={props.editCustomer}
        deleteCustomer={props.deleteCustomer}
        hosts={props.hosts}
        teamID={props.teamID}
        openAccessFrame={props.openAccessFrame}
        migrating={props.migrating}
      />
    ),
    path: 'list/*',
  }
  let customerTabs = [customerTab]
  if (licenseViewEnabled && AuthRole.hasPermission('viewLicenses')) {
    customerTabs[0] = {
      ...customerTab,
      name: 'Connected customers',
    }
    customerTabs.push({
      name: 'License view',
      id: 'tab-customerslicense',
      additionalClass: 'rowview',
      component: <LicenseView teamID={props.teamID} />,
      path: 'licenses',
    })
  }

  customerTabs.push({
    name: 'Status board',
    id: 'tab-customersstatus',
    additionalClass: 'rowview',
    component: (
      <MyHosts
        addCustomer={props.addCustomer}
        editCustomer={props.editCustomer}
        deleteCustomer={props.deleteCustomer}
        hosts={props.hosts}
        teamID={props.teamID}
        openAccessFrame={props.openAccessFrame}
        migrating={props.migrating}
        view='status'
      />
    ),
    path: 'status-board/*',
  })

  const match = useMatch('/my-customers/:subPath/*')
  const tab = customerTabs.find(t => t.path.replace('/*', '').endsWith(match?.params.subPath ?? 'no match')) ?? customerTabs[0]

  return (
    <>
      <div className='tab' id='tab-customers'>
        <HostPropertiesFilter />
        <header>
          <div className='auto'>
            <h2>My customers</h2>
            <div className='tabs'>
              <ul className='auto'>
                {customerTabs.map(t => (
                  <RouteTabLink key={t.path} path={t.path}>
                    {t.name}
                  </RouteTabLink>
                ))}
              </ul>
            </div>
          </div>
        </header>
        <div id={tab.id} className={`tab auto ${tab.additionalClass ? tab.additionalClass : ''}`}>
          <Routes>
            {customerTabs.map(t => (
              <Route key={t.path} path={t.path} element={t.component} />
            ))}
            <Route path='*' element={<Navigate to='list' />} />
          </Routes>
        </div>
      </div>
    </>
  )
}

function sliceIntoChunks(arr: any[], chunkSize: number): any[][] {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

function isExpired(demoMode: boolean, lastUpdate: Date | undefined): boolean {
  return !demoMode && moment(lastUpdate).isBefore(moment().subtract(10, 'hours'))
}

const getSystemHealthInfo = (teamID: string, hosts: HostData[], signal: AbortSignal) => {
  const ids = hosts.map(h => h.id).join(',')
  return axios
    .get<BulkApiResponse[]>(`/api/teams/${teamID}/customers/system-health/read_many`, {
      timeout,
      signal,
      params: { ids },
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
    .catch(_ => {
      return Promise.reject()
    })
}

const getAccountDetails = (teamID: string, hosts: HostData[], signal: AbortSignal) => {
  const ids = hosts.map(h => h.id).join(',')
  return axios
    .get<BulkApiResponse[]>(`/api/teams/${teamID}/customers/account/read_many`, { timeout, signal, params: { ids } })
    .then(resp => {
      return Promise.resolve(resp)
    })
    .catch(_ => {
      return Promise.reject()
    })
}
