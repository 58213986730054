import React, { FunctionComponent } from 'react'
import { MeshIcon, PeopleIcon, PrinterIcon } from '../Icons/Icons'

export const HiveServiceHealthTab: FunctionComponent = () => {
  return (
    <div id='tab-impact' className='tab visible'>
      <header className='flex flex-ver'>
        <h2>Edge Mesh health</h2>
      </header>
      <div className='content upgrade-req coming-soon tmp-stronger-fade'>
        <hr />
        <div className='row flex'>
          <div className='col third'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <MeshIcon />
                </i>
                <h6>Edge Mesh</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Overall health</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Super nodes</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Standard nodes</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Passive nodes</h5>
                  <div className='status'></div>
                </div>
              </div>
            </div>
          </div>
          <div className='col third'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <PeopleIcon />
                </i>
                <h6>Users</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Total users</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Active users</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Inactive users</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Pending invites</h5>
                  <div className='status'></div>
                </div>
              </div>
            </div>
          </div>
          <div className='col third'>
            <div className='card fh zebra'>
              <header>
                <i className='icon'>
                  <PrinterIcon />
                </i>
                <h6>Printers</h6>
              </header>
              <div className='compact'>
                <div className='set'>
                  <h5>Total printers</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Printers in error</h5>
                  <div className='status'></div>
                </div>
                <div className='set'>
                  <h5>Toner required</h5>
                  <div className='status'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
