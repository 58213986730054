import React, { FunctionComponent, useEffect, useState } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { useTypedSelector } from '../../redux/reducers'
import { useMountEffect } from '../../hooks/MountEffect'
import { clearHostPropertiesFilter, setHostPropertiesFilter, toggleFilterPanel } from '../../redux/hostsFilter'
import { isFeatureOn } from '../../redux/selectors'
import { Features } from '../../features'

export const HostPropertiesFilter: FunctionComponent = () => {
  const showPropsFilter = useTypedSelector(r => r.filter.showFilterPanel)
  const [crnFilter, setCrnFilter] = useState('')
  const [operatingSystemFilter, setOperatingSystemFilter] = useState('')
  const [versionMin, setVersionMin] = useState('')
  const [versionMax, setVersionMax] = useState('')
  const [devicesMin, setDevicesMin] = useState('')
  const [devicesMax, setDevicesMax] = useState('')
  const [filteredProducts, setFilteredProducts] = useState<{ [key: string]: boolean }>({
    MF: false,
    NG: false,
    HIVE: false,
  })
  const hiveProvisioningEnabled = useTypedSelector(isFeatureOn(Features.HiveProvisioning))
  const hiveConnectExistingEnabled = useTypedSelector(isFeatureOn(Features.HiveConnectExisting))
  const hiveEnabled = hiveProvisioningEnabled || hiveConnectExistingEnabled
  const [filteredSupport, setFilteredSupport] = useState<{ [key: string]: boolean }>({
    eol: false,
    eolPlanned: false,
  })
  const support = useTypedSelector(state => state.support)
  const hasEndOfLife = support.eolMajorVersion !== ''
  const hasEOLPlanned = support.eolPlannedMajorVersion !== ''
  const usingSupportFilter = filteredSupport.eol || filteredSupport.eolPlanned
  let activeFilter = useTypedSelector(state => state.filter.filter, shallowEqual)
  const dispatch = useDispatch()

  useMountEffect(() => {
    if (activeFilter) {
      setCrnFilter(activeFilter.crn)
      setOperatingSystemFilter(activeFilter.operatingSystem)
      setDevicesMin(activeFilter.devicesMin)
      setDevicesMax(activeFilter.devicesMax)
      setVersionMin(activeFilter.versionMin)
      setVersionMax(activeFilter.versionMax)
      setFilteredProducts(activeFilter.products)
      setFilteredSupport(activeFilter.support)
    }
  })

  useEffect(() => {
    if (filteredSupport.eol) {
      setVersionMin('')
      setVersionMax(filteredSupport.eolPlanned ? support.eolPlannedMajorVersion : support.eolMajorVersion)
    } else if (filteredSupport.eolPlanned) {
      setVersionMin((parseInt(support.eolMajorVersion) + 1).toString())
      setVersionMax(support.eolPlannedMajorVersion)
    } else {
      setVersionMin('')
      setVersionMax('')
    }
  }, [filteredSupport, support])

  function handleFilterApply(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    propertiesFilter()
  }

  function anyFilterEntered(): boolean {
    let fields = [crnFilter, operatingSystemFilter, versionMin, versionMax, devicesMin, devicesMax]
    return fields.some(field => field.trim() !== '') || Object.values(filteredProducts).some(val => val) || Object.values(filteredSupport).some(val => val)
  }

  const propertiesFilter = () => {
    dispatch(toggleFilterPanel())
    let currentFilter = {
      crn: crnFilter,
      products: filteredProducts,
      versionMin: versionMin,
      versionMax: versionMax,
      devicesMin: devicesMin,
      devicesMax: devicesMax,
      operatingSystem: operatingSystemFilter,
      support: filteredSupport,
    }

    if (!anyFilterEntered()) {
      dispatch(clearHostPropertiesFilter())
      return
    }

    dispatch(setHostPropertiesFilter(currentFilter))
  }

  function clearPropertiesFilter(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    setCrnFilter('')
    setOperatingSystemFilter('')
    setDevicesMin('')
    setDevicesMax('')
    setVersionMin('')
    setVersionMax('')
    setFilteredProducts({ MF: false, NG: false, HIVE: false })
    setFilteredSupport({ eol: false, eolPlanned: false })
    dispatch(toggleFilterPanel())
    dispatch(clearHostPropertiesFilter())
  }

  function handleCancel(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    // reset to active filter
    if (activeFilter) {
      setCrnFilter(activeFilter.crn)
      setOperatingSystemFilter(activeFilter.operatingSystem)
      setDevicesMin(activeFilter.devicesMin)
      setDevicesMax(activeFilter.devicesMax)
      setVersionMin(activeFilter.versionMin)
      setVersionMax(activeFilter.versionMax)
      setFilteredProducts(activeFilter.products)
      setFilteredSupport(activeFilter.support)
      dispatch(toggleFilterPanel())
    } else {
      clearPropertiesFilter(e)
    }
  }

  return (
    <>
      {showPropsFilter && (
        <div className='popover'>
          <form onSubmit={propertiesFilter}>
            <header>
              <h4>Filters</h4>
            </header>

            <div className='row'>
              <label>CRN</label>
              <div className='flex flex-ver'>
                <input type='text' placeholder='C-XXXXXX' value={crnFilter} onChange={e => setCrnFilter(e.currentTarget.value)} />
              </div>
            </div>

            <div className='row'>
              <label>Product</label>
              <div className='row flex'>
                <div className='col half'>
                  <label className='inl' htmlFor='license-MF'>
                    <input
                      type='checkbox'
                      id='license-MF'
                      checked={filteredProducts.MF}
                      onChange={() =>
                        setFilteredProducts({
                          ...filteredProducts,
                          MF: !filteredProducts.MF,
                        })
                      }
                    />
                    PaperCut MF
                  </label>
                  <label className='inl' htmlFor='license-NG'>
                    <input
                      type='checkbox'
                      id='license-NG'
                      onChange={() =>
                        setFilteredProducts({
                          ...filteredProducts,
                          NG: !filteredProducts.NG,
                        })
                      }
                      checked={filteredProducts.NG}
                    />
                    PaperCut NG
                  </label>
                </div>
                {hiveEnabled && (
                  <div className='col half'>
                    <label className='inl' htmlFor='license-hive'>
                      <input
                        type='checkbox'
                        id='license-hive'
                        onChange={() =>
                          setFilteredProducts({
                            ...filteredProducts,
                            HIVE: !filteredProducts.HIVE,
                          })
                        }
                        checked={filteredProducts.HIVE}
                      />
                      PaperCut Hive
                    </label>
                  </div>
                )}
              </div>
              <label>Version support</label>
              <div className='row flex'>
                <div className='col half'>
                  <label className={`inl has-tip ${hasEndOfLife ? '' : 'disabled'}`} htmlFor='eol'>
                    <input
                      type='checkbox'
                      id='eol'
                      disabled={!hasEndOfLife}
                      onChange={() =>
                        setFilteredSupport({
                          ...filteredSupport,
                          eol: !filteredSupport.eol,
                        })
                      }
                      checked={filteredSupport.eol}
                    />
                    End of life
                    {!hasEndOfLife && <span className='tip'>End-of-life filtering is currently unavailable.</span>}
                  </label>
                </div>
                <div className='col half'>
                  <label className={`inl has-tip ${!hasEOLPlanned || !hasEndOfLife ? 'disabled' : ''}`} htmlFor='eol-planned'>
                    <input
                      type='checkbox'
                      id='eol-planned'
                      disabled={!hasEOLPlanned || !hasEndOfLife}
                      onChange={() =>
                        setFilteredSupport({
                          ...filteredSupport,
                          eolPlanned: !filteredSupport.eolPlanned,
                        })
                      }
                      checked={filteredSupport.eolPlanned}
                    />
                    End of life planned
                    {!hasEOLPlanned && hasEndOfLife && <span className='tip'>No planned end-of-life products have been announced.</span>}
                    {!hasEndOfLife && <span className='tip'>End-of-life filtering is currently unavailable.</span>}
                  </label>
                </div>
              </div>
              <label>Version</label>
              <div className='flex flex-ver'>
                <div className='multi-field'>
                  <input
                    type='text'
                    placeholder={usingSupportFilter ? '' : '19.2'}
                    value={versionMin}
                    disabled={usingSupportFilter}
                    onChange={e => setVersionMin(e.currentTarget.value)}
                  />
                </div>
                <span className='join'>to</span>
                <div className='multi-field'>
                  <input
                    type='text'
                    placeholder={usingSupportFilter ? '' : '20.0'}
                    value={versionMax}
                    disabled={usingSupportFilter}
                    onChange={e => setVersionMax(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <label>Devices</label>
              <div className='flex flex-ver'>
                <div className='multi-field'>
                  <input type='text' placeholder='0' value={devicesMin} onChange={e => setDevicesMin(e.currentTarget.value)} />
                </div>
                <span className='join'>to</span>
                <div className='multi-field'>
                  <input type='text' placeholder='1000' value={devicesMax} onChange={e => setDevicesMax(e.currentTarget.value)} />
                </div>
              </div>
            </div>

            <div className='row'>
              <label>Operating system</label>
              <div className='flex flex-ver'>
                <input type='text' placeholder='Windows 10' value={operatingSystemFilter} onChange={e => setOperatingSystemFilter(e.currentTarget.value)} />
              </div>
            </div>

            <footer>
              <a className='action' href='#' onClick={clearPropertiesFilter}>
                Clear all filters
              </a>
              <a className='btn secondary' onClick={handleCancel}>
                Cancel
              </a>
              <button onClick={handleFilterApply} className='btn'>
                Apply
              </button>
            </footer>
          </form>
        </div>
      )}
    </>
  )
}
